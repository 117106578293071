import { Http, HttpAuth } from '../../config/Http'
import { changeLoading } from './loading.action'
import { changeNotify } from './notify.action'

export const actionTypes = {
    CHANGEDOUBLE: 'MENSAGEM_CHANGEDOUBLE',
    CHANGECRASH: 'MENSAGEM_CHANGECRASH',
    CHANGEROLETA: 'MENSAGEM_CHANGEROLETA',
    ERROR: 'MENSAGEM_ERROR',
    SUCCESS: 'MENSAGEM_SUCCESS',
    SHOW:'MENSAGEM_SHOW',
    INDEX:'MENSAGEM_INDEX',
    CHANGEPREMIUM:'MENSAGEM_CHANGEPREMIUM',
    CHANGEFANTAN:'MENSAGEM_CHANGEFANTAN',
    CHANGEFUTBALLSTUDIO:'MENSAGEM_CHANGEFUTBALLSTUDIO',
    CHANGEPENALTY:'MENSAGEM_CHANGEPENALTY',
    CHANGEAVIATOR:'MENSAGEM_CHANGEAVIATOR',
    CHANGEMINER:'MENSAGEM_CHANGEMINER',
    CHANGEFORTUNETIGER:'MENSAGEM_CHANGEFORTUNETIGER',
    CHANGEVARIOSLOT: 'MENSAGEM_CHANGEVARIOSLOT',
}


export const changevarioslot = (payload) => ({
    type: actionTypes.CHANGEVARIOSLOT,
    payload
})

export const changeminer = (payload) => ({
    type: actionTypes.CHANGEMINER,
    payload
})


export const changedouble = (payload) => ({
    type: actionTypes.CHANGEDOUBLE,
    payload
})
export const changecrash = (payload) => ({
    type: actionTypes.CHANGECRASH,
    payload
})
export const changepremium = (payload) => ({
    type: actionTypes.CHANGEPREMIUM,
    payload
})

export const changefortunetiger = (payload) => ({
    type: actionTypes.CHANGEFORTUNETIGER,
    payload
})
export const changefantan = (payload) => ({
    type: actionTypes.CHANGEFANTAN,
    payload
})
export const changefutballstudio = (payload) => ({
    type: actionTypes.CHANGEFUTBALLSTUDIO,
    payload
})
export const changepenalty = (payload) => ({
    type: actionTypes.CHANGEPENALTY,
    payload
})
export const changeaviator = (payload) => ({
    type: actionTypes.CHANGEAVIATOR,
    payload
})



export const changeroleta = (payload) => ({
    type: actionTypes.CHANGEROLETA,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})



export const indexResponse = (payload) => ({
    type: actionTypes.INDEX,
    payload
})

export const showResponse =(payload) => ({
    type: actionTypes.SHOW,
    payload,
})

export const index = () => dispatch => {
    return HttpAuth.get('/grupo')
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}


//VARIOS SLOTS
export const showVarioSlot = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showvarioslot/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updateVarioSlot = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatevarioslot/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}





export const store = data => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando usuário...'
    }));
  
    return HttpAuth.post('/grupo', data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch( changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}

export const updatedouble = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatedouble/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

export const updatefantan = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatefantan/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

export const updatefutballstudio = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatefutballstudio/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

export const updatecrash = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatecrash/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

//Mensagem premium
export const updatepremium = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatecpremium/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

export const showpremium = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showcpremium/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}



export const showdouble = (id) => dispatch => {
    return HttpAuth.get('/mensagem/showdouble/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const showfantan = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showfantan/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}
export const showfutballstudio = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showfutballstudio/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const showcrash = (id) => dispatch => {
    return HttpAuth.get('/mensagem/showcrash/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

//Roleta
//#################  Roleata PADRAO##################
export const showroleta = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showroleta/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updateroleta = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updateroleta/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
     })
}

//Penalty
export const showpenalty = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showpenalty/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updatepenalty = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatepenalty/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

//Aviator
export const showaviator = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showaviator/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updateaviator = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updateaviator/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}


//Miner
export const showminer = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showminer/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updateminer = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updateminer/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}


//#################  FurtuneTiger ##################
export const showfurtunetiger = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showfortunetiger/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updatefurtunetiger = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatefortunetiger/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
     })
}



///################# PADRAO ##################




export const showVarioSlotpadrao = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showvarioslotpadrao/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updateVarioSlotpadrao = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatevarioslotpadrao/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}


///################# Fantan PADRAO ##################
export const showfantanpadrao = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showfantanpadrao/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updatefantanpadrao = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatefantanpadrao/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}



//#################  Aviator PADRAO##################
export const showaviatorpadrao = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showaviatorpadrao/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updateaviatorpadrao = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updateaviatorpadrao/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

//#################  Miner PADRAO##################
export const showminerpadrao = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showminerpadrao/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updateminerpadrao = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updateminerpadrao/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

//#################  FootballStudio PADRAO##################
export const updatefutballstudiopadrao = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatefutballstudiopadrao/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

export const showfutballstudiopadrao = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showfutballstudiopadrao/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

//#################  Penalty PADRAO##################
export const showpenaltypadrao = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showpenaltypadrao/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updatepenaltypadrao = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatepenaltypadrao/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
     })
}

//#################  Premium PADRAO##################
export const updatepremiumpadrao = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatecpremiumpadrao/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

export const showpremiumpadrao = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showcpremiumpadrao/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

//#################  FurtuneTiger PADRAO##################
export const showfurtunetigerpadrao = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showfortunetigerpadrao/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updatefurtunetigerpadrao = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatefortunetigerpadrao/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
     })
}


//#################  Roleata PADRAO##################
export const showroletapadrao = (id,tipo) => dispatch => {
    return HttpAuth.get('/mensagem/showroletapadrao/'+id+'/tipo/'+tipo)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updateroletapadrao = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updateroletapadrao/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
     })
}
