import { Http, HttpAuth } from '../../config/Http'
import { changeLoading } from './loading.action'
import { changeNotify } from './notify.action'

export const actionTypes = {
    CHANGE: 'ESTRATEGIA_CHANGE',
    CHANGELIST: 'ESTRATEGIA_CHANGELIST',
    CLEARLIST: 'ESTRATEGIA_CLEARLIST',
    DELETELIST: 'ESTRATEGIA_DELETELIST',
    ERROR: 'ESTRATEGIA_ERROR',
    SUCCESS: 'ESTRATEGIA_SUCCESS',
    SHOW:'ESTRATEGIA_SHOW',
    INDEX:'ESTRATEGIA_INDEX',
    
    CHANGECRASH: 'ESTRATEGIA_CHANGECRASH',
    CHANGECRASHPREMIUM:'ESTRATEGIA_CHANGECRASHPREMIUM',
    CHANGEDOUBLEPREMIUM:'ESTRATEGIA_CHANGEDOUBLEPREMIUM',
    CHANGEFANTAN:'ESTRATEGIA_CHANGEFANTAN',
    CHANGEFUTBALLSTUDIO:'ESTRATEGIA_CHANGEFUTBALLSTUDIO',
    CHANGEPENALTY:'ESTRATEGIA_CHANGEPENALTY',
    CHANGEAVIATOR:'ESTRATEGIA_CHANGEAVIATOR',
    CHANGEMINER:'ESTRATEGIA_CHANGEMINER',
    CHANGEFORTUNETIGER:'ESTRATEGIA_CHANGEFORTUNETIGER',
    CHANGEVARIOSLOT:'ESTRATEGIA_CHANGEVARIOSLOT',
}

export const changevarioslot= (payload) => ({
    type: actionTypes.CHANGEVARIOSLOT,
    payload
})

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const changelist = (payload) => ({
    type: actionTypes.CHANGELIST,
    payload
})

export const changefortunetiger = (payload) => ({
    type: actionTypes.CHANGEFORTUNETIGER,
    payload
})

export const changecrash = (payload) => ({
    type: actionTypes.CHANGECRASH,
    payload
})

export const changecrashpremium = (payload) => ({
    type: actionTypes.CHANGECRASHPREMIUM,
    payload
})

export const changedoublepremium = (payload) => ({
    type: actionTypes.CHANGEDOUBLEPREMIUM,
    payload
})
export const changefantan = (payload) => ({
    type: actionTypes.CHANGEFANTAN,
    payload
})
export const changefutballstudio = (payload) => ({
    type: actionTypes.CHANGEFUTBALLSTUDIO,
    payload
})

export const changeaviator = (payload) => ({
    type: actionTypes.CHANGEAVIATOR,
    payload
})
export const changepenalty = (payload) => ({
    type: actionTypes.CHANGEPENALTY,
    payload
})

export const changeminer = (payload) => ({
    type: actionTypes.CHANGEMINER,
    payload
})
export const clearlist = () => ({
    type: actionTypes.CLEARLIST

})

export const deletelist = (payload) => ({
    type: actionTypes.DELETELIST,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})


export const indexResponse = (payload) => ({
    type: actionTypes.INDEX,
    payload
})


export const showResponse =(payload) => ({
    type: actionTypes.SHOW,
    payload,
})


//VARIOSLOT #########################################
export const updateVarioSlot = (idbot,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Estrategia'
    }))
   
    return HttpAuth.put(`/estrategia/updatevarioslot/${idbot}`,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
    })
}

export const showVarioSlot = (id) => dispatch => {
    return HttpAuth.get('/estrategia/showvarioslot/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}


//MINER #########################################
export const updateMiner = (idbot,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Estrategia Miner'
    }))
   
    return HttpAuth.put(`/estrategia/updateminer/${idbot}`,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
    })
}

export const showMiner = (id) => dispatch => {
    return HttpAuth.get('/estrategia/showminer/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}


//AVIATOR #########################################
export const indexaviator = (id) => dispatch => {
    return HttpAuth.get('/estrategia/indexaviator/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}

export const showaviator = (id,botid) => dispatch => {
    return HttpAuth.get(`estrategia/showaviator/${id}/${botid}`)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const storeaviator = (idbot,data,list) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando estrategia...'
    }));
     
    
    list.forEach(res=>{
        if(data.sequencia == null){
            data.sequencia = `${res.name}`
        }else{
          data.sequencia = `${data.sequencia},${res.name}`
       }
    })

    console.log(data.sequencia)
    console.log(data)
    return HttpAuth.post('/estrategia/storeaviator/'+idbot, data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch( changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}


export const updateaviator = (idbot,data,list) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Estrategia'
    }))
    data.sequencia = null;
    list.forEach(res=>{
        if(data.sequencia == null){
            data.sequencia = `${res.name}`
        }else{
          data.sequencia = `${data.sequencia},${res.name}`
       }
    })
    return HttpAuth.put(`/estrategia/updateaviator/${data.id}/${idbot}`,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
    })
}

export const deletaraviator = (id,botid) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Excluindo Estrategia'
    }))

    return HttpAuth.delete(`estrategia/deleteaviator/${id}/${botid}`)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(indexaviator(botid))
                         
                      }
                 }
           })
}
export const updatestatusaviator = (id,botid) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Status'
    }))
   
    return HttpAuth.put(`/estrategia/updatestatusaviator/${id}/${botid}`)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(indexaviator(botid))
                         
                      }
                 }
    })
}


//Futballstudio #########################################
export const indexfutballstudio = (id) => dispatch => {
    return HttpAuth.get('/estrategia/indexfutballstudio/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}

export const showfutballstudio = (id,botid) => dispatch => {
    return HttpAuth.get(`estrategia/showfutballstudio/${id}/${botid}`)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const storefutballstudio = (idbot,data,list) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando estrategia...'
    }));
     
    
    list.forEach(res=>{
        if(data.sequencia == null){
            data.sequencia = `${res.name}`
        }else{
          data.sequencia = `${data.sequencia},${res.name}`
       }
    })

    console.log(data.sequencia)
    console.log(data)
    return HttpAuth.post('/estrategia/storefutballstudio/'+idbot, data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch( changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}


export const updatefutballstudio = (idbot,data,list) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Estrategia'
    }))
    data.sequencia = null;
    list.forEach(res=>{
        if(data.sequencia == null){
            data.sequencia = `${res.name}`
        }else{
          data.sequencia = `${data.sequencia},${res.name}`
       }
    })
    return HttpAuth.put(`/estrategia/updatefutballstudio/${data.id}/${idbot}`,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
    })
}

export const deletarfutballstudio = (id,botid) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Excluindo Estrategia'
    }))

    return HttpAuth.delete(`estrategia/deletefutballstudio/${id}/${botid}`)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(indexfutballstudio(botid))
                         
                      }
                 }
           })
}
export const updatestatusfutballstudio = (id,botid) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Status'
    }))
   
    return HttpAuth.put(`/estrategia/updatestatusfutballstudio/${id}/${botid}`)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(indexfutballstudio(botid))
                         
                      }
                 }
    })
}



//Fantan #########################################
export const indexfantan = (id) => dispatch => {
    return HttpAuth.get('/estrategia/indexfantan/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}
export const showfantan = (id,botid) => dispatch => {
    return HttpAuth.get(`estrategia/showfantan/${id}/${botid}`)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}
export const storefantan = (idbot,data,list) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando estrategia...'
    }));
     
    
    list.forEach(res=>{
        if(data.sequencia == null){
            data.sequencia = `${res.name}`
        }else{
          data.sequencia = `${data.sequencia},${res.name}`
       }
    })

    return HttpAuth.post('/estrategia/storefantan/'+idbot, data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch( changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}
export const updatefantan = (idbot,data,list) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Estrategia'
    }))
    data.sequencia = null;
     console.log(list)
    list.forEach(res=>{
        if(data.sequencia == null){
            data.sequencia = `${res.name}`
        }else{
          data.sequencia = `${data.sequencia},${res.name}`
       }
    })
    return HttpAuth.put(`/estrategia/updatefantan/${data.id}/${idbot}`,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
    })
}
export const deletarfantan = (id,botid) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Excluindo Estrategia'
    }))

    return HttpAuth.delete(`estrategia/deletefantan/${id}/${botid}`)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(indexfantan(botid))
                         
                      }
                 }
           })
}
export const updatestatusfantan = (id,botid) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Status'
    }))
   
    return HttpAuth.put(`/estrategia/updatestatusfantan/${id}/${botid}`)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(indexfantan(botid))
                         
                      }
                 }
    })
}

//Pentaly #########################################
export const updatePenalty = (idbot,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Estrategia Penatly'
    }))
   
    return HttpAuth.put(`/estrategia/updatepenalty/${idbot}`,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
    })
}

export const showPenalty = (id) => dispatch => {
    return HttpAuth.get('/estrategia/showpenalty/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

//DOUBLE #########################################
export const index = (id) => dispatch => {
    return HttpAuth.get('/estrategia/index/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}

export const show = (id,botid) => dispatch => {
    return HttpAuth.get(`estrategia/show/${id}/${botid}`)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const store = (idbot,data,list) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando estrategia...'
    }));
     
    
    list.forEach(res=>{
        if(data.sequencia == null){
            data.sequencia = `${res.name}`
        }else{
          data.sequencia = `${data.sequencia},${res.name}`
       }
    })

    console.log(data.sequencia)
    console.log(data)
    return HttpAuth.post('/estrategia/store/'+idbot, data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch( changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}


export const update = (idbot,data,list) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Estrategia'
    }))
    data.sequencia = null;
     console.log(list)
    list.forEach(res=>{
        if(data.sequencia == null){
            data.sequencia = `${res.name}`
        }else{
          data.sequencia = `${data.sequencia},${res.name}`
       }
    })
    return HttpAuth.put(`/estrategia/update/${data.id}/${idbot}`,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
    })
}

export const deletar = (id,botid) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Excluindo Estrategia'
    }))

    return HttpAuth.delete(`estrategia/delete/${id}/${botid}`)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(index(botid))
                         
                      }
                 }
           })
}


//Crash ##################################
export const storecrash = (idbot,data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando estrategia...'
    }));
  
    return HttpAuth.post('/estrategia/storecrash/'+idbot, data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch( changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}

export const indexcrash = (id) => dispatch => {
    return HttpAuth.get('/estrategia/indexcrash/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}

export const showcrash = (id,botid) => dispatch => {
    return HttpAuth.get(`estrategia/showcrash/${id}/${botid}`)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updatecrash = (idbot,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Estrategia'
    }))
  
    return HttpAuth.put(`/estrategia/updatecrash/${data.id}/${idbot}`,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}


export const deletecrash = (id,botid) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Excluindo Estrategia'
    }))

    return HttpAuth.delete(`estrategia/deletecrash/${id}/${botid}`)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}
//FORTUNETIGER #########################################
export const updateFortuneTiger = (idbot,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Estrategia Miner'
    }))
   
    return HttpAuth.put(`/estrategia/updatefortunetiger/${idbot}`,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
    })
}

export const showFortuneTiger = (id) => dispatch => {
    return HttpAuth.get('/estrategia/showfortunetiger/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}


//CrashPremium
export const indexcrashpremium = (id) => dispatch => {
    return HttpAuth.get('/estrategia/indexcrashpremium/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}
export const storecrashpremium = (idbot,data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando estrategia...'
    }));
  
    return HttpAuth.post('/estrategia/storecrashpremium/'+idbot, data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch( changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}
export const showcrashpremium = (id) => dispatch => {
    return HttpAuth.get(`estrategia/showcrashpremium/${id}`)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}
export const updatecrashpremium = (idbot,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Estrategia'
    }))
  
    return HttpAuth.put(`/estrategia/updatecrashpremium/${idbot}`,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}
export const deletecrashpremium = (id,botid) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Excluindo Estrategia'
    }))

    return HttpAuth.delete(`estrategia/deletecrashpremium/${id}/${botid}`)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

//Double Premium
export const indexdoublepremium = (id) => dispatch => {
    return HttpAuth.get('/estrategia/indexdoublepremium/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}
export const storedoublepremium = (idbot,data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando estrategia...'
    }));
  
    return HttpAuth.post('/estrategia/storedoublepremium/'+idbot, data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch( changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}
export const showdoublepremium = (id,botid) => dispatch => {
    return HttpAuth.get(`estrategia/showdoublepremium/${id}`)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}
export const updatedoublepremium = (idbot,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Estrategia'
    }))
  
    return HttpAuth.put(`/estrategia/updatedoublepremium/${idbot}`,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}
export const deletedoublepremium = (id,botid) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Excluindo Estrategia'
    }))

    return HttpAuth.delete(`estrategia/deletedoublepremium/${id}/${botid}`)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

//bot
export const reinciarbot = (id) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Status'
    }))
 
    return HttpAuth.post('grupo/reiniciarbot/'+id)
           .then(res =>{
              
                 
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                      if(res.data.error){
                          dispatch(success(false));
                          dispatch(error(res.data.error));
                      }

                      if(res.status === 201){
                        dispatch(success(false));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                         
                      }
                 }
           })
}



