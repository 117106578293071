import {actionTypes} from '../actions/mensagem.action';

const initialState = {
    mensagens:{
        data:[]
    },
    mensagemdouble:{
      
    },
    mensagemfantan:{
      
    },

    mensagemfutballstudio:{
      
    },
    mensagemaviator:{
        tipo:1,
    },
    
    mensagempenalty:{
      tipo:1,
    },

    
    mensagemcrash:{
      
    },

    mensagempremium:{
      
    },

    mensagemroleta:{
      
    },

    mensageminer:{
      
    },

    mensagemfurtunetiger:{
      
    },
    mensagemvarioslot:{
      
    },

    
    success:false,
    error:{},
 
    status:[],
    
}


export default (state = initialState, { type, payload }) => {
    switch (type) {

    case actionTypes.INDEX:
      
        return { ...state, ...payload }

    case actionTypes.SHOW:
      
        return { ...state, ...payload }
      
        case actionTypes.CHANGEVARIOSLOT:
            return{ 
                ...state,
                mensagemvarioslot:{
                        ...state.mensagemvarioslot,
                        ...payload
                    }
    }    
    case actionTypes.CHANGEDOUBLE:
        return{ 
            ...state,
            mensagemdouble:{
                 ...state.mensagemdouble,
                 ...payload
             }
    }
    case actionTypes.CHANGEFANTAN:
        return{ 
            ...state,
            mensagemfantan:{
                    ...state.mensagemfantan,
                    ...payload
                }
    }
    case actionTypes.CHANGEFORTUNETIGER:
        return{ 
            ...state,
            mensagemfurtunetiger:{
                ...state.mensagemfurtunetiger,
                ...payload
            }
    }
    case actionTypes.CHANGEFUTBALLSTUDIO:
        return{ 
            ...state,
            mensagemfutballstudio:{
                    ...state.mensagemfutballstudio,
                    ...payload
                }
    }
    case actionTypes.CHANGEAVIATOR:
        return{ 
            ...state,
            mensagemaviator:{
                    ...state.mensagemaviator,
                    ...payload
                }
    }
    case actionTypes.CHANGEPENALTY:
        return{ 
            ...state,
            mensagempenalty:{
                    ...state.mensagempenalty,
                    ...payload
                }
    }
        
    case actionTypes.CHANGECRASH:
         return{ 
             ...state,
             mensagemcrash:{
                 ...state.mensagemcrash,
                 ...payload
            }
    }
    case actionTypes.CHANGEPREMIUM:
        return{ 
            ...state,
            mensagempremium:{
                ...state.mensagempremium,
                ...payload
            }
    }
    case actionTypes.CHANGEROLETA:
        return{ 
            ...state,
            mensagemroleta:{
                ...state.mensagemroleta,
                ...payload
            }
        }
    case actionTypes.CHANGEMINER:
        return{ 
            ...state,
            mensageminer:{
                ...state.mensageminer,
                ...payload
            }
    }
    case actionTypes.SUCCESS:
        return{ 
            ...state, 
            success: payload
         }
    case actionTypes.ERROR:
        return{
            ...state,
            error: payload
        }
    default:
        return state
    }
}
