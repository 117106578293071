import React,{Suspense,lazy} from 'react';

import {BrowserRouter as Router,Route,Switch} from "react-router-dom"
import {CircularProgress} from '@material-ui/core';
const Login =  lazy (() => import ('./view/pages/login'));








const Usuario =  lazy (() => import ('./view/pages/usuario/edit'));
const Usuarios =  lazy (() => import ('./view/pages/usuario/index'));


const Arquivo =  lazy (() => import ('./view/pages/Arquivo/index'));
const Grupos =  lazy (() => import ('./view/pages/grupo/index'));
const Grupo =  lazy (() => import ('./view/pages/grupo/edit'));

const Jogos =  lazy (() => import ('./view/pages/jogo/index'));
const Jogo =  lazy (() => import ('./view/pages/jogo/edit'));

const Double =  lazy (() => import ('./view/pages/mensagem/double'));
const Crash =  lazy (() => import ('./view/pages/mensagem/crash'));

const PremiumMessagem =  lazy (() => import ('./view/pages/mensagem/premium'));

const Estrategia =  lazy (() => import ('./view/pages/estrategia/edit'));
const Estrategias =  lazy (() => import ('./view/pages/estrategia/index'));

const EstrategiasCrash =  lazy (() => import ('./view/pages/estrategia/indexcrash'));
const EstrategiaCrash =  lazy (() => import ('./view/pages/estrategia/editcrash'));

const EstrategiaCrashPrmeium =  lazy (() => import ('./view/pages/estrategia/editCrashPremium'));
const EstrategiaDoublePrmeium =  lazy (() => import ('./view/pages/estrategia/editDoublePremium'));


const EstrategiasRoleta =  lazy (() => import ('./view/pages/estrategia/roleta/index'));
const EstrategiaRoleta =  lazy (() => import ('./view/pages/estrategia/roleta/edit'));
const Roleta =  lazy (() => import ('./view/pages/mensagem/roleta'));

const Permissoes =  lazy (() => import ('./view/pages/usuario/permissoes'));
const Senha =  lazy (() => import ('./view/pages/usuario/alterar'));


//Fantan
const EstrategiaFantan =  lazy (() => import ('./view/pages/estrategia/fantan/edit'));
const EstrategiasFantan =  lazy (() => import ('./view/pages/estrategia/fantan/index'));
const Fantan =  lazy (() => import ('./view/pages/mensagem/fantan'));

//Futballstudio
const EstrategiaFuball =  lazy (() => import ('./view/pages/estrategia/fuballstudio/edit'));
const EstrategiasFuball =  lazy (() => import ('./view/pages/estrategia/fuballstudio/index'));
const FutballStudio =  lazy (() => import ('./view/pages/mensagem/futballstudio'));

//penalty
const EstrategiaPenalty =  lazy (() => import ('./view/pages/estrategia/penalty/edit'));
const EstrategiaPenaltyMensagem =  lazy (() => import ('./view/pages/mensagem/penalty'));


//AVIATOR
const EstrategiaAviator =  lazy (() => import ('./view/pages/estrategia/aviator/edit'));
const EstrategiasAviator =  lazy (() => import ('./view/pages/estrategia/aviator/index'));

//miner
const EstrategiaMiner=  lazy (() => import ('./view/pages/estrategia/miner/edit'));
const EstrategiaMinerMensagem =  lazy (() => import ('./view/pages/mensagem/miner'));

//furtunetiger
const EstrategiaFortunerTiger=  lazy (() => import ('./view/pages/estrategia/fortunetiger/edit'));
const FurtuneTigerMensagem =  lazy (() => import ('./view/pages/mensagem/furtunetiger'));

const Aviator =  lazy (() => import ('./view/pages/mensagem/aviator'));

//Varios slots
const EstrategiaVarioSlot=  lazy (() => import ('./view/pages/estrategia/varioslot/edit'));
const VarioSlotMensagem=  lazy (() => import ('./view/pages/mensagem/varioslots'));



// ########### PADRAO #######################

//fantan
const FantanPadrao =  lazy (() => import ('./view/pages/mensagempadrao/fantan'));

const AviatorPadrao =  lazy (() => import ('./view/pages/mensagempadrao/aviator'));

const MinerPadrao =  lazy (() => import ('./view/pages/mensagempadrao/miner'));

const FutballStudioPardrao =  lazy (() => import ('./view/pages/mensagempadrao/futballstudio'));

const PenaltyPadrao =  lazy (() => import ('./view/pages/mensagempadrao/penalty'));

const CPremiumPadrao =  lazy (() => import ('./view/pages/mensagempadrao/premium'));

const FurtuneTigerPadrao =  lazy (() => import ('./view/pages/mensagempadrao/furtunetiger'));

const RoletaPadrao =  lazy (() => import ('./view/pages/mensagempadrao/roleta'));

const VariosSlotPadrao = lazy (() => import ('./view/pages/mensagempadrao/varioslots'));

const Routes = () =>(
    <Router>
        <Suspense fallback={<div className="d-flex justify-content-center mt-5 pt-5"><CircularProgress /></div>}>
              <Switch>



                  {/*Usuario*/}
                  <Route path="/usuario" exact component={Usuario} />
                  <Route path="/usuario/:id" exact component={Usuario} />
                  <Route path="/usuarios" exact component={Usuarios} />

                  <Route path="/grupos" exact component={Grupos} />
                  <Route path="/grupo" exact component={Grupo} />
                  <Route path="/grupo/:id" exact component={Grupo} />

                  <Route path="/jogos" exact component={Jogos} />
                  <Route path="/jogo" exact component={Jogo} />
                  <Route path="/jogo/:id" exact component={Jogo} />

                  <Route path="/double" exact component={Double} />
                  <Route path="/double/:id" exact component={Double} />
                  
                  <Route path="/crash/:id" exact component={Crash} />

                  <Route path="/PremiumMessagem/:id" exact component={PremiumMessagem} />
                  

                  <Route path="/estrategias/:botid" exact component={Estrategias} />
                  <Route path="/estrategia/:botid" exact component={Estrategia} />
                  <Route path="/estrategia/:botid/:id" exact component={Estrategia} />

                  <Route path="/estrategiascrash/:botid" exact component={EstrategiasCrash} />
                  <Route path="/estrategiacrash/:botid" exact component={EstrategiaCrash} />
                  <Route path="/estrategiacrash/:botid/:id" exact component={EstrategiaCrash} />


                  <Route path="/estrategiascrashpremium/:id" exact component={EstrategiaCrashPrmeium} />
                  <Route path="/estrategiasdoublepremium/:id" exact component={EstrategiaDoublePrmeium} />


                  
                  <Route path="/estrategiasroleta/:botid" exact component={EstrategiasRoleta} />
                  <Route path="/estrategiaroleta/:botid/:id" exact component={EstrategiaRoleta} />
                  <Route path="/roleta/:id" exact component={Roleta} />

                  {/*Fantan*/}
                  <Route path="/estrategiasfantan/:botid" exact component={EstrategiasFantan} />
                  <Route path="/estrategiafantan/:botid" exact component={EstrategiaFantan} />
                  <Route path="/estrategiafantan/:botid/:id" exact component={EstrategiaFantan} />
                  <Route path="/fantan" exact component={Fantan} />
                  <Route path="/fantan/:id" exact component={Fantan} />

                  {/*Futballstudio*/}
                  <Route path="/estrategiafutball/:botid" exact component={EstrategiaFuball} />
                  <Route path="/estrategiafutball/:botid/:id" exact component={EstrategiaFuball} />
                  <Route path="/estrategiasfutball/:botid" exact component={EstrategiasFuball} />
                  <Route path="/futball" exact component={FutballStudio} />
                  <Route path="/futball/:id" exact component={FutballStudio} />
                  
                   {/*Penalty*/}
                  <Route path="/penalty" exact component={EstrategiaPenalty} />
                  <Route path="/penalty/:id" exact component={EstrategiaPenalty} />
                  <Route path="/penaltymensagem/" exact component={EstrategiaPenaltyMensagem} />
                  <Route path="/penaltymensagem/:id" exact component={EstrategiaPenaltyMensagem} />
                  
                  {/*Aviator*/}
                  <Route path="/estrategiaaviator/:botid" exact component={EstrategiaAviator} />
                  <Route path="/estrategiaaviator/:botid/:id" exact component={EstrategiaAviator} />
                  <Route path="/estrategiasaviator/:botid" exact component={EstrategiasAviator} />
                  <Route path="/aviator" exact component={Aviator} />
                  <Route path="/aviator/:id" exact component={Aviator} />

                  {/*Miner*/}
                  <Route path="/miner" exact component={EstrategiaMiner} />
                  <Route path="/miner/:id" exact component={EstrategiaMiner} />
                  <Route path="/minermensagem/" exact component={EstrategiaMinerMensagem} />
                  <Route path="/minermensagem/:id" exact component={EstrategiaMinerMensagem} />

                  {/*Fortune Tiger*/}
                  <Route path="/fortunetiger" exact component={EstrategiaFortunerTiger} />
                  <Route path="/fortunetiger/:id" exact component={EstrategiaFortunerTiger} />
                  <Route path="/fortunetigermensagem/" exact component={FurtuneTigerMensagem} />
                  <Route path="/fortunetigermensagem/:id" exact component={FurtuneTigerMensagem} />
                  
                  {/*Roletas*/}
                  <Route path="/estrategiasroleta/:botid" exact component={EstrategiasRoleta} />
                  <Route path="/estrategiaroleta/:botid/:id" exact component={EstrategiaRoleta} />
                  <Route path="/estrategiaroleta/:botid" exact component={EstrategiaRoleta} />
                  <Route path="/roleta/" exact component={Roleta} />
                  <Route path="/roleta/:id" exact component={Roleta} />

                   {/*Varios SLOT*/}
                  <Route path="/varioslot" exact component={EstrategiaVarioSlot} />
                  <Route path="/varioslot/:id" exact component={EstrategiaVarioSlot} />
                  <Route path="/varioslotmensagem/" exact component={VarioSlotMensagem} />
                  <Route path="/varioslotmensagem/:id" exact component={VarioSlotMensagem} />


                  

                  {/*Permissao*/}
                  <Route path="/permissoes/:id" exact component={Permissoes} />

                  <Route path="/senha" exact component={Senha} />
             
                  {/* ########################### Padrao #######################*/}
                 
                  {/*Fantan Padrao*/}
                  <Route path="/fantanpadrao" exact component={FantanPadrao} />
                  <Route path="/fantanpadrao/:id" exact component={FantanPadrao} />
                 
                 {/*Aviator Padrao*/}
                  <Route path="/aviatorpadrao" exact component={AviatorPadrao} />
                  <Route path="/aviatorpadrao/:id" exact component={AviatorPadrao} />

                 {/*Miner Padrao*/}
                 <Route path="/minermensagempadrao/" exact component={MinerPadrao} />
                 <Route path="/minermensagempadrao/:id" exact component={MinerPadrao} />

                 {/*Futballstudio Padrao*/}
                  <Route path="/futballpadrao" exact component={FutballStudioPardrao} />
                  <Route path="/futballpadrao/:id" exact component={FutballStudioPardrao} />

                  {/*Penalty Padrao*/}
                  <Route path="/penaltymensagempadrao/" exact component={PenaltyPadrao} />
                  <Route path="/penaltymensagempadrao/:id" exact component={PenaltyPadrao} />

                  
                  {/*CPremium Padrao*/}
                  <Route path="/cpremiummensagempadrao/" exact component={CPremiumPadrao} />
                  <Route path="/cpremiummensagempadrao/:id" exact component={CPremiumPadrao} />

                  {/*Fortune Tiger Padrao*/}
                  <Route path="/fortunetigermensagempadrao/" exact component={FurtuneTigerPadrao} />
                  <Route path="/fortunetigermensagempadrao/:id" exact component={FurtuneTigerPadrao} />

                  {/*Roleta Padrao*/}
                  <Route path="/roletamensagempadrao/" exact component={RoletaPadrao} />
                  <Route path="/roletamensagempadrao/:id" exact component={RoletaPadrao} />

                  {/*VARIOS SLOT Padrao*/}
                  <Route path="/varioslotmensagempadrao/" exact component={VariosSlotPadrao} />
                  <Route path="/varioslotmensagempadrao/:id" exact component={VariosSlotPadrao} />

      

                    
                  <Route path="/login" exact   component={Login} />
                  <Route path="/"   component={Login} />

              </Switch>
        </Suspense>
    </Router>
);

export default Routes;